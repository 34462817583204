// In this file the bootstrap variables can be set to change the bootstrap styling, see node_modules/bootstrap/scss/variables

$dark-white: #f1f1f1;
$dark-grey-primary: #6f777f;
$dark-grey-secondary: #484e53;
$dark-grey-alternative: #31363b;
$wri-dark-grey: #f8fafc;

$procornea-green: #0095ad;
$procornea-gray: #626367;
$next-color: #fbba00;
$good: #00a149;
$bad: #e5142c;
$tooltip: #0095ad;

$light-black: #333333;
$light-grey-primary: #ebebeb;
$light-grey-secondary: #dedede;
$light-grey-alternative: #c2c2c2;

$font-size-base: 0.9rem;
$font-family-sans-serif: 'Segoe UI', 'Segoe WP', 'Helvetica Neue', sans-serif;
$font-family-base: $font-family-sans-serif;

$bondi-blue: #0095ad;
$mineshaft: #333333;
$ohanu-aqua: #ccffff;
$congress-blue: #014380;
$pomegranade: #ea530e;
$greenhaze: #01a149;
$purple: purple;

$alabaster-white: #f8f8f8; //248
$athens-grey: #f3f3f5; //243
$seashell: #f1f1f1; //241
$gallery-grey: #eeeeee; //238
$alto-grey: #dedede; //222
$alto: #cfcfcf; //207
$silver: #c2c2c2; //194
$concrete: #f2f2f2;

$outer-space: #313436;
$shuttle-grey: #5e656b;
$rollingstone-grey: #7c7e80;
$rainy-grey: #6f777f;
$abby-grey: #484e53;
$mako-black: #3c4248;
$mako: #3c4248;
$space-black: #31363b;
$capecod-grey: #3a3f43;
$pro-black: #2c2f31;
$gray: #888888;

$primary: $bondi-blue;
$secondary: $alto-grey;
$primary-selected: #8bc9d3;
$success: #00a149;
$danger: lighten(#e5142c, 5%);
$warning: #fbba00;
$info: $bondi-blue;

$navbar-dark-color: white;
$navbar-dark-hover-color: $silver;
$navbar-dark-active-color: white;
$navbar-dark-disabled-color: $silver;

$input-bg: $alto-grey;
$input-color: $mineshaft;
$input-placeholder-color: $silver;
$input-border-width: 0;

$component-active-bg: transparent;
$custom-control-indicator-checked-bg: none;
$custom-control-indicator-bg: $input-bg;
$custom-control-indicator-checked-bg: $input-bg;
$custom-control-indicator-checked-color: $input-color;

$transition-collapse: none;

$tooltip-arrow-color: $bondi-blue;
$tooltip-bg: $bondi-blue;
$tooltip-color: $secondary;
$form-group-margin-bottom: 0.3em;

$form-control-glow: 0px 0px 3px 1px $procornea-green;

$fitting-header-height: 70px;
