@import 'bootstrap-custom-variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

.reviewDialogImageOptions,
.powerProfileDialogImageOptions {
    background-color: $seashell;
    border-radius: $border-radius;
}
.order-history {
    background-color: $wri-dark-grey;
}
.body,
.content-section {
    background-color: $white;
    color: $mineshaft;
}

mpc-modal .mpc-modal .mpc-modal-body {
    background: $white;
    color: $mineshaft;
}

.input-group-addon {
    &.open {
        background-color: $dark-white;
    }

    &.blocked {
        background-color: #f7f7f7;
        right: 0;
        padding-left: 10px;
        padding-right: 5px;
    }
}

a:not(.nav-link):not(.dropdown-item) {
    color: $bondi-blue;
}

.productdatasheetlink {
    color: $mineshaft !important;
}

.btn-inverted {
    background-color: $gallery-grey !important;
    color: $mineshaft !important;
    border: 0 solid !important;
    font-weight: bold !important;

    &:hover {
        background-color: $silver !important;
    }
}

/* Toastr modifications */
.toast-container {
    .toast {
        width: 450px;
    }

    margin-bottom: 60px;

    .toast-info {
        background-image: none !important;
        background-color: $silver;
        border-color: $silver;
        color: white;
    }
}

.toast-container > .toast-info:before {
    font-size: 24px;
    line-height: 18px;
    float: left;
    color: #fff;
    padding-right: 0.5em;
    margin: auto 0.5em auto -1.5em;
}

.modal-content {
    background-color: white !important;

    .modal-footer {
        color: $mineshaft;
        background-color: white;
    }

    .modal-body {
        color: $mineshaft;
    }

    .modal-overlay {
        background: white;
    }
}

.main {
    background: url('/assets/images/eye-light.png');
}

.content {
    color: $mineshaft;
}

.title-border-bottom,
.modal-header {
    border-bottom: solid 2px $alto-grey !important;
}

.title-border-bottom2 {
    border-bottom: solid 2px $black !important;
}

.title-border-top {
    border-top: solid 2px $mineshaft !important;
}

.dreamlitehistory {
    .selected {
        background-color: #1f6b51;
        color: #fff;
    }
    table {
        th,
        td {
            border: 1px solid $alto-grey;
        }
    }
}

.pagination {
    button {
        border-color: #333 !important;
        color: #333;

        &.selectedPage {
            background: #333;
            color: #fff;
        }
    }
}

.range-slider {
    .range-slider_dots {
        .dot:before {
            background: #d4d4d4;
        }
    }

    .range-slider_range {
        background: #f2f2f2;

        &::-webkit-slider-thumb {
            background: $procornea-green;
        }

        &::-moz-range-thumb {
            background: $procornea-green;
        }
    }

    .range-slider_value {
        color: #fff;
        background: $procornea-green;

        &:after {
            border-right-color: $procornea-green !important;
        }
    }
}

.notepanel {
    .notecontainer {
        .notewindow {
            background-color: $light-grey-primary;
        }
    }

    .note-psside {
        background-color: $light-grey-secondary;
    }

    .ps-chat {
        .nomessages {
            background: $light-grey-primary !important;
        }

        .chat-type {
            background: rgba($light-grey-primary, 0.8);
        }

        .client-communication {
            background: $light-grey-primary;
        }
    }
}

.selectallwrapper,
.measurement-block {
    .status,
    .checkedIcon {
        border-color: #333;
    }
}

.ps-datatable {
    .table_entry {
        border-bottom: 1px solid #e8e8e8;

        &:hover,
        &_selected {
            background: $dark-white;
        }
    }
}

.form-control {
    background-color: $gallery-grey !important;
    color: $mineshaft !important;
}

.search {
    input {
        background-color: rgba(0, 0, 0, 0.6) !important;
        color: white !important;
    }

    .input-group-text {
        background-color: rgba(0, 0, 0, 0.6) !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: white;
    }
}

.home-nav.cards {
    .card {
        @include gradient-y($start-color: white, $end-color: $alto-grey, $start-percent: 0%, $end-percent: 100%);
        background-color: white !important;
        border-color: white !important;
    }

    .card:hover {
        @include gradient-y(
            $start-color: white,
            $end-color: rgba($bondi-blue, 0.3),
            $start-percent: 0%,
            $end-percent: 100%
        );
        background-color: white !important;
    }
}

.cards {
    .card {
        color: $bondi-blue;
        background-color: $seashell !important;
        border-color: white;
        box-shadow: 0 0 10px $silver;

        .card-body {
            .info {
                color: $silver;
            }
        }
    }

    .card:hover {
        color: $bondi-blue;
        background-color: white !important;
    }
}

.landingBackground {
    background: $seashell url('/assets/images/bg-eye-light.png') -600px -200px no-repeat; //Not working with relative paths
}

hr {
    color: $silver;
    background-color: $silver;
}

footer {
    background-color: $concrete;
    color: $mineshaft;

    a {
        color: $mineshaft !important;
        text-decoration: none !important;
    }
}

.content a {
    color: $mineshaft;
}

.clientsearchresult:hover,
.recentlyopenedclientsresult:hover {
    background-color: $alto-grey;
}

.imageMoon::before {
    color: $mineshaft;
}

.accented-background {
    background-color: $alabaster-white !important;
}

.accented-background2 {
    background-color: $gallery-grey;
}

.accented-background3 {
    background-color: $white;
}

.default-message {
    color: $alto;
}

.collapse-label {
    color: $mineshaft;
}

.process-nav {
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .process-nav-1 {
        background-image: linear-gradient(to right, white, white 30px, rgba($bondi-blue, 0.3));
    }

    .process-nav-2 {
        background-image: linear-gradient(to right, $gallery-grey, white 30px, white);
    }

    .step-past .step-inner,
    .step-current .step-inner {
        border-top: solid 4px white;
        border-bottom: solid 4px white;
    }

    .arrow {
        border-left: 19px solid white;
        background-color: $gallery-grey;
    }

    .arrow:after {
        border-left: 16px solid rgba($bondi-blue, 0.3);
    }
}

.process-nav-ps {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(to right, $gallery-grey, white 30px, white);
}

.continue-process,
.spare-warning {
    background: rgba(255, 255, 255, 0.95);
}

.loadingoverlay {
    color: $mineshaft;
    background-color: rgba(0, 0, 0, 0.7);

    .loading {
        background-color: $alto-grey;
    }
}

.clienttabs {
    .client-tab:not(.active) {
        a:hover {
            color: $mineshaft !important;
        }

        a {
            color: $silver !important;
        }
    }
}

.btn-link {
    background-color: $greenhaze !important;
    border: none !important;
    color: $white !important;
}

.btn-secondary,
.btn-default {
    background-color: $silver !important;
    border: none !important;

    &:hover,
    &.active {
        background-color: darken($silver, 10%) !important;
    }
}

.pre {
    background-color: $seashell;
    font-family: monospace;
    white-space: pre;
}

.client-communication {
    background-color: $seashell;

    .message-content,
    .attachment-content {
        background-color: $white;

        &.sent {
            background-color: lighten($primary-selected, 15%);
            color: $black;
        }

        &.received {
            background-color: lighten($silver, 10%);
        }
    }
}

.datatable tbody tr {
    color: black;
    background-color: $gallery-grey;

    &:hover {
        background-color: darken($gallery-grey, 5%);
    }
}

.datatable {
    a {
        color: $rollingstone-grey !important;
    }

    .selected {
        background-color: lighten($primary-selected, 15%);

        &:hover {
            background-color: darken($primary-selected, 5%);
        }
    }

    .datatableheader {
        color: $mineshaft;
    }
}

.tab-container {
    .nav-tabs {
        border-bottom-color: $nav-tabs-border-color !important;

        .nav-item {
            .active {
                background-color: $alabaster-white !important;
            }

            .nav-link {
                border-bottom: 0;

                &.active {
                    background-color: white !important;
                    border-left-color: $nav-tabs-border-color !important;
                    border-top-color: $nav-tabs-border-color !important;
                    border-right-color: $nav-tabs-border-color !important;
                }

                h3 {
                    color: $mineshaft !important;
                }
            }

            :hover {
                border-left-color: $nav-tabs-border-color !important;
                border-top-color: $nav-tabs-border-color !important;
                border-right-color: $nav-tabs-border-color !important;
            }
        }
    }

    .tab-pane.active {
        background-color: white;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-left: $nav-tabs-border-width solid $nav-tabs-border-color;
        border-right: $nav-tabs-border-width solid $nav-tabs-border-color;
        border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
    }
}

.measurementitemcontainer {
    background-color: $seashell;

    &:hover {
        background-color: darken($gallery-grey, 5%);
    }

    &.selected {
        background-color: rgba($bondi-blue, 0.2);
    }
}

fitting-refraction {
    background-color: $alabaster-white;
}

fitting-fitlens {
    background-color: $alabaster-white;
}

.subjectivemeasurement {
    background-color: white;
}

.previousmeasurement {
    background-color: $gallery-grey;
}

.currentmeasurement {
    background-color: white;
}

.lenstype {
    background-color: $white;
    border-color: $silver;

    &:not(.disabled):hover .lenstypeIcon {
        filter: invert(0.9);
    }

    .lenstypeIcon {
        filter: invert(0.35);
    }

    &.disabled {
        color: darken($silver, 20%);
        background-color: lighten($silver, 15%);
    }
}

.lensparameters {
    background-color: white;
}

.diagnosis {
    background-color: white;
}

.disabled {
    color: rgba($color: $mineshaft, $alpha: 0.4) !important;
}

.baseline .badge,
.badge.manual-measurement-badge {
    color: $black;
}

.badge-outline {
    border: solid 1px $mineshaft;

    &.badge-colors {
        border-color: $mineshaft;
        color: $mineshaft;
    }
}

tabset {
    .nav-link {
        color: $mineshaft !important;
    }
}

.fileupload-wrapper {
    border: 1px solid darken($gallery-grey, 10%);
    background-color: $gallery-grey;
}

.fileupload {
    .drop-container {
        border: 2px dashed darken($gallery-grey, 10%);
    }
}

.pills-tab {
    border-bottom: 1px $alto solid;

    &.active {
        background-color: $athens-grey;
    }
}

.tabs {
    a.nav-link {
        color: $primary !important;

        &.active {
            background-color: $athens-grey;
            color: $mineshaft !important;
        }

        &:hover {
            color: $mineshaft !important;
        }

        border-bottom: 1px solid $secondary;
    }
}

.tabcontent {
    background-color: $athens-grey;
}

.settingsbody {
    background-color: white;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #333;
    }
}

.ld-check {
    color: $alto-grey;
}
.message-container {
    background: url('/assets/images/order-background.svg') no-repeat;
    background-size: auto 100%;
    background-position: top right;
    height: calc(100vh - 160px);
}

@include media-breakpoint-up(md) {
    fitting-client-search .accented-background {
        box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.1);
    }
}

@include media-breakpoint-down(sm) {
    fitting-client-search .accented-background {
        box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.1);
    }

    .message-container {
        background-color: white;
    }
}

.orderstatusitem,
.serviceitem {
    background-color: $white;

    &:hover {
        background-color: darken($white, 5%);
    }

    &.selected {
        background-color: $bondi-blue;
    }

    .statusbar {
        background-color: $alabaster-white;
        border-left-color: $alabaster-white;
    }
}

.orderstatusfilterbar,
.servicefilterbar {
    background-color: darken($concrete, 5%);
}

.search {
    input,
    .input-group-append {
        color: #fff !important;
        border-bottom: 2px solid $gray !important;
    }
    .input-group-text {
        color: #fff !important;
    }
    input::placeholder {
        color: $gray !important;
    }
}

.sort,
.filter {
    button {
        color: $mineshaft !important;
        border-bottom: 2px solid $gray !important;
    }
}

.shop-sidebar .menuitem {
    &.selected,
    &:hover {
        background-color: $gallery-grey;
    }
}

@include media-breakpoint-down(lg) {
    .shop-contentwrapper.accented-background {
        box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.1);
    }
}

@include media-breakpoint-up(lg) {
    .shop-contentwrapper.accented-background {
        box-shadow: inset 7px 0 15px -7px rgba(0, 0, 0, 0.3);
    }
}

.shopitem {
    background-color: $white;

    &:hover {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    }

    &.selected {
        background-color: $bondi-blue;
        color: $white;
    }
}

table.shoppingcart {
    tbody {
        tr {
            background-color: $white;
        }

        td.first {
            border-left: 1px solid $silver;
        }

        td.last {
            border-right: 1px solid $silver;
        }

        td {
            border-top: 1px solid $silver;
            border-bottom: 1px solid $silver;
        }
    }
}

tr.hover-effect:hover {
    background: $dark-white;
}

.orderreferencepanel {
    background-color: $alto-grey;
}

.visavyCE {
    background: url('/assets/images/visavy-ce-light.png');
}

.clientnotesbackground {
    background-color: white;
}

.theme-opposite {
    background-color: $light-black;
    color: white;
}

.grey-primary {
    background-color: $light-grey-primary;
    color: black;
}

.grey-secondary {
    background-color: $light-grey-secondary;
    color: black;
}

.grey-alternative {
    background-color: $light-grey-alternative;
    color: black;
}

.disabled {
    background-color: $light-grey-primary;
    color: darken($light-grey-primary, 20%) !important;
}

.mergeclient {
    background-color: $alabaster-white;
}

.mergeclient:hover {
    background-color: $athens-grey;
}

.service-client {
    background-color: $gallery-grey;

    &:hover {
        background-color: darken($gallery-grey, 5%);
    }
}

.service-lensselecttablerow {
    border-radius: $border-radius;
    background-color: $gallery-grey;

    &:hover {
        background-color: darken($gallery-grey, 5%);
    }
}

.selected {
    border-radius: $border-radius;
    background-color: $primary-selected;

    &:hover {
        background-color: darken($primary-selected, 10%);
    }
}

.sticky-element {
    background: rgba(255, 255, 255, 0.95);
}

.table-item:hover {
    background-color: $light-grey-secondary;
}

.disabledConfig {
    background: rgba(255, 255, 255, 0.8);
}

.wp-block-table table {
    width: 100%;
    border: 1px solid #333333;
    margin-bottom: 20px;

    tr {
        td {
            border: 1px solid #333333;
            padding: 5px;
        }
    }
}

.editor {
    border-radius: 4px;
    margin-top: 1rem;
    text-align: left;

    .NgxEditor__MenuBar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: 2px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .NgxEditor {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 2px solid rgba(0, 0, 0, 0.2);
        border-top: none;
    }

    .CodeMirror {
        border: 1px solid #eee;
        height: auto;
        margin-bottom: 0.7rem;

        pre {
            white-space: pre !important;
        }
    }
}
